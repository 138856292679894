import { ModuleResolutionKind } from 'typescript';
import { bymeldingServiceUrl } from '../api/urls';
import httpClient from '../api/httpClient';

/* eslint-disable import/prefer-default-export */
const kartverketBaseUrl = 'https://ws.geonorge.no/adresser/v1/';

export interface KartverketAdresse {
  adressetekst: string;
  lat: number;
  lon: number;
}

export const geoLookup = async (
  lat: number,
  lon: number
): Promise<KartverketAdresse | null> => {
  try {
    const test = await httpClient.getAdresse(lat.toString(), lon.toString());
    if (test?.result) return { lat, lon, adressetekst: test.result };
    return null;
  } catch (error) {
    console.log(`Geo lookup error: ${error}`);
    return null;
  }
};

export const adresseLookup = async (
  soketekst: string
): Promise<KartverketAdresse[] | []> => {
  try {
    const url = `${`${kartverketBaseUrl}sok?side=0&asciiKompatibel=true&treffPerSide=10&filtrer=adresser.adressetekst%2Cadresser.representasjonspunkt&utkoordsys=4258&kommunenavn=oslo&sok=${soketekst}*`}`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: { 'Cache-Control': 'no-cache' },
    });

    const result = await resp.json();

    if (result.adresser.length === 0) return [];

    return result.adresser.map(
      (adresse: {
        adressetekst: string;
        representasjonspunkt: { lat: number; lon: number };
      }) => {
        return {
          adressetekst: adresse.adressetekst,
          lat: adresse.representasjonspunkt.lat,
          lon: adresse.representasjonspunkt.lon,
        };
      }
    );
  } catch (error) {
    return [];
  }
};
